<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
    <div class="position-relative">
      <div class="container-xl">
        <div class="row">
          <div class="col-12 col-lg-6 d-md-flex justify-content-center flex-column pt-5 pt-lg-0">
            <div class="">
              <!--<h1 class="mt-4">{{tr('winner-congrat')}}</h1>-->
              <img :src="`${baseUrl}/lang_images/winnings/hero_${$store.state.currentLang}.jpg`" class="img-fluid pt-3 pt-lg-0 winner_h1">
              <p class="main-block__hero--p pt-3 text-white">{{tr('winner-ervenyes-palyazat-eseten')}}</p>
              <p class="main-block__hero--p--big text-blue pt-3 fw-bold" v-html="tr('winner-hero-sub-h1-main')"></p>            
              <!--<p class="main-block__hero--p--big text-blue pt-3" v-if="winnerType == 'macskakajacsomag'">{{ tr('winner-hero-sub-h1-weekly') }}</p>-->
              <!-- <p class="main-block__hero--p">{{ tr('winner-hero-sub-h1-daily') }}</p>-->
              <button v-if="needBtn" class="btn btn-primary mt-4 mb-4 d-none d-lg-block" v-scroll-to="{ el: '#winnerForm', offset: -80 }">{{tr('kerem-a-nyeremenyem')}}</button>
            </div>
          </div>      
          <!-- IMG - desctop     -->
          <div class="col-12 col-lg-5 offset-lg-1 align-self-center pt-lg-5 pt-0">
            <div class="winnerType-bgr">
                <img v-if="winnerType == 'main'" :src="`${baseUrl}/lang_images/winnings/fodij_1_${$store.state.currentLang}.png`" :alt="tr('winnings-img-alt-azonnali')" class="img-fluid" />
                <img v-if="winnerType == 'okosora'" :src="`${baseUrl}/lang_images/winnings/fodij_2_${$store.state.currentLang}.png`" :alt="tr('winnings-img-alt-heti')" class="img-fluid" />
                <img v-if="winnerType == 'okos_fulhallgato'" :src="`${baseUrl}/lang_images/winnings/fodij_3_${$store.state.currentLang}.png`" :alt="tr('winnings-img-alt-fodij')" class="img-fluid" />
            </div>
          </div>
          </div>
        </div>   
    </div>
    <button v-if="needBtn" class="btn btn-primary mt-4 mb-4 d-block d-lg-none mx-auto" v-scroll-to="{ el: '#winnerForm', offset: -80 }">{{tr('kerem-a-nyeremenyem')}}</button>
    <p class="real-info-txt mb-0 text-center ml-auto mr-auto">{{tr('winner-hero-info')}}</p>
  </div>
</div>
</template>

<script>
export default {
    props:['winnerType', 'needBtn']
}
</script>